import { graphql } from 'gatsby'
import React from 'react'

import animation from '../../assets/blog/16/1.gif'
import img1 from '../../assets/blog/16/2.png'
import img2 from '../../assets/blog/16/3.png'
import heroImg from '../../assets/blog/16/hero.jpg'
import '../../styles/global.css'
import { ContentMain } from '../../components/common/content-main'
import { ContentSubPage } from '../../components/common/content-sub-page'
import { HeaderTitle } from '../../components/common/header-title'
import { CookiesBox } from '../../components/cookies-box'
import { Footer } from '../../components/footer'
import { Navigation } from '../../components/navigation'
import { SEO } from '../../components/seo'

const BlogPage = () => (
  <>
    <SEO title="Jak přežít stavbu domu" />
    <div className="flex flex-col h-screen justify-between">
      <Navigation />
      <ContentMain>
        <HeaderTitle title="Jak přežít stavbu domu" />
        <ContentSubPage>
          <div className="w-3/4 mx-auto my-12">
            <img
              src={heroImg}
              alt="Jak přežít stavbu domu"
              className="object-cover w-full rounded-[20px]"
            />
          </div>
          <p>
            Lidé se prý nejvíc rozvádějí kvůli stavbě či rekonstrukci, říká
            lidová moudrost. Jen ten, kdo tímto procesem prošel, ví, jakou
            vztahovou zkouškou umí takový zážitek být. Nedávno jsme stavbu
            vlastního bydlení prožívali z druhé ruky, s našimi kamarády. A ještě
            že nás měli :-) Vedle spousty nevyžádaných rad se dočkali jednoho
            tipu, za který jsou nám dodnes vděční.
          </p>
          <p>
            Stáhli si aplikaci &ndash; organizér. Ano, takhle jednoduše. Najít
            ale tu správnou, která by si poradila s tak složitým projektem, není
            vůbec jednoduché.
          </p>
          <p>
            Flagis se ale osvědčil. Funguje totiž na principu štítků, skupin a
            podskupin, které se mohou překrývat přesně tak, jak potřebujete. Pod
            štítkem Stavba se tak může seskupit vše, co s ní jakkoliv souvisí.
            Jednotlivé položky už ale můžete jednoduchým přidáním štítku
            přiřadit do dalších kategorií, třeba podle řemesel. A to, co se
            objeví ve skupině Elektrikář, Truhlář, či Instalatér, si stejným
            způsobem můžete přiřadit i k nákupnímu seznamu, podle typu obchodu
            či třeba pod kategorii Úřady. Odkazy na e-shopy či stránky s
            inspirací se také velmi osvědčily. Díky nastavení termínů umí Flagis
            spolehlivě hlídat i platby, včetně uložení faktur, nebo domluvené
            schůzky.
          </p>
          <p>
            Další užitečná funkce, kterou kamarádi hojně využívali, je posílání
            úkolů mezi sebou. Každý věděl, kdo má co na starosti. Flexibilně
            bylo možné reagovat na situaci, kdy se jeden z nich neplánovaně
            ocitl na místě, kde bylo možné nějaký úkol vyřešit místo partnera.
            Případně naopak, když se nepovedlo danou věc zařídit. Díky možnosti
            potvrzovat přijetí i splnění úkolu měli oba přehled o tom, co už
            mají hotovo.
          </p>
          <p>
            Jejich tvrzení, že bez Flagisu by asi ani nedostavěli, je jistě
            přehnané. Ale čas, nervy a možná i nějakou tu partnerskou krizi jim
            ta správná aplikace ušetřila určitě.
          </p>

          <div className="my-5">
            <a href={animation} target="_blank" rel="noreferrer">
              <img
                src={animation}
                alt="Demo showcase"
                className="object-contain"
              />
            </a>
          </div>
          <div className="my-5 flex justify-around">
            <a href={img1} target="_blank" rel="noreferrer">
              <img src={img1} alt="Demo showcase" className="object-contain" />
            </a>
          </div>
          <div className="my-5 flex justify-around">
            <a href={img2} target="_blank" rel="noreferrer">
              <img src={img2} alt="Demo showcase" className="object-contain" />
            </a>
          </div>
        </ContentSubPage>
      </ContentMain>
      <Footer />
    </div>
    <CookiesBox />
  </>
)

export default BlogPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
